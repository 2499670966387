import {useAuth0} from "@auth0/auth0-vue"
import axios from "axios";
// import {reactive} from "vue";

const state = {token: ""}

export default function callApi() {
    const url = process.env.VUE_APP_API_URL
    const axiosInstance = axios


    function getToken(){
        return state.token
    }

    function getURL(){
        return url
    }

    async function setToken(){
        state.token = await useAuth0().getAccessTokenSilently()
        // console.log(state.token)
    }



    // Custom function to format parameters without brackets
    const formatParams = (params) => {
        const queryString = Object.entries(params)
            .map(([key, value]) => {
                if (Array.isArray(value)) {
                    return value.map(item => `${key}=${encodeURIComponent(item)}`).join('&');
                } else if (typeof value === 'object' && value !== null) {
                    return formatParams(value, key);
                } else {
                    return `${key}=${encodeURIComponent(value)}`;
                }
            })
            .join('&');

        return queryString;
    };

    async function apiGet(endpoint, data=[], get_file=false, file_type){
        if(state.token == ""){
            state.token = await useAuth0().getAccessTokenSilently()
        }
        const header = {headers:{Authorization: "Bearer "+ state.token}}
        if(get_file || file_type == 'PDF'){
            header.responseType = 'arraybuffer'
        }else if(file_type == 'Download'){
            header.responseType = 'blob'
        }

        // return axios.get(url+endpoint, {params: data}, header)
        return axiosInstance.get(`${url}${endpoint}?${formatParams(data)}`, header)
    }
    
    const progress_array = []

    // progress_object must contain a "progress" ref value, and a "url" string value
    async function apiDownloadFile(endpoint, data=[], progress_object = null){
        let requestInterceptor = null
        if(progress_object !== null && progress_object.value.url != null && progress_object.value.progress != null){
            progress_array.push(progress_object)
            requestInterceptor = axiosInstance.interceptors.request.use(config => {
                // Add an download progress handler
                config.onDownloadProgress = progressEvent => {
                    for(let i=0; i<progress_array.length; i++){
                        const progress_object = progress_array[i]
                        if(progress_object.value.url != null){
                            if(progressEvent.event.target.responseURL.startsWith(progress_object.value.url)){
                                progress_object.value.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            }
                        }
                   }
                };
                return config;
            });
        }

        if(state.token == ""){
            state.token = await useAuth0().getAccessTokenSilently()
        }
        const header = {headers:{Authorization: "Bearer "+ state.token}}
        header.responseType = 'blob'

        const response = await axiosInstance.get(`${url}${endpoint}?${formatParams(data)}`, header)

        if(requestInterceptor !== null){
            axiosInstance.interceptors.request.eject(requestInterceptor)
        }
        if(progress_object !== null){
            // Find the index of the object to remove
            const indexToRemove = progress_array.indexOf(progress_object);
            progress_array.splice(indexToRemove, 1)
        }

        return response
    }

    async function apiPost(endpoint, data={}, json=false, timeout=null){
        if(state.token == ""){
            state.token = await useAuth0().getAccessTokenSilently()
        }
        const header = {headers:{Authorization: "Bearer "+ state.token}}
        if(timeout){
            header.timeout = timeout
        }
        if(!data){
            return axiosInstance.post(url+endpoint, header)
        }
        if(!json){
            let form = new FormData();
            for(const key in data){
                if (Array.isArray(data[key])) {
                    // It's an array, so you can loop through it
                    for (let i = 0; i < data[key].length; i++) {
                        form.append(key, data[key][i]);
                    }
                } else {
                    form.append(key, data[key]);
                }
            }
            return axiosInstance.post(url+endpoint, form, header)
        }else{
            // const jsonData = JSON.stringify(data);
            return axiosInstance.post(url+endpoint, data, header)
        }

    }

    async function apiUploadFile(endpoint, file, file_name = null, data=null, progress_ref = null){
        if(file_name == null){
            file_name = file.name
        }
        if(data == null){
            data = {}
        }
        const chunkSize = 10 * 1024 * 1024; // 10MB per chunk
        const totalChunks = Math.ceil(file.size / chunkSize);
        // console.log("Total Chunks:", totalChunks)
        let chunk_index = 0
        let requestInterceptor = null
        try{
            if(progress_ref){
                requestInterceptor = axiosInstance.interceptors.request.use(config => {
                    // Add an upload progress handler
                    config.onUploadProgress = progressEvent => {
                        progress_ref.value = Math.round(((chunk_index + (progressEvent.loaded / progressEvent.total) - 1)/totalChunks) * 100);
                    };
                    return config;
                });
            }


            for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                chunk_index = chunkIndex

                const start = chunkIndex * chunkSize;
                const end = Math.min(start + chunkSize, file.size);
                const fileChunk = file.slice(start, end);
                data.file = fileChunk
                data.chunk_index = chunkIndex
                data.total_chunks = totalChunks
                data.file_name = file_name

                // const data = {
                //     'file': fileChunk,
                //     'chunk_index': chunkIndex,
                //     'total_chunks': totalChunks,
                //     'file_name': file_name
                // }
                try {
                    // console.log("Uploading Chunk:", chunk_index)
                    await apiPost(endpoint, data, false, 0)
                } catch (error) {
                    console.error('Error uploading chunk:', error);
                    break;
                }
            }
        }finally {
            if(requestInterceptor !== null){
                axiosInstance.interceptors.request.eject(requestInterceptor)
            }
        }


    }

    async function apiPut(endpoint, data){
        if(state.token == ""){
            state.token = await useAuth0().getAccessTokenSilently()
        }
        const header = {headers:{Authorization: "Bearer "+ state.token}}
        return axiosInstance.put(url+endpoint, data, header)
    }

    async function apiDelete(endpoint){
        if(state.token == ""){
            state.token = await useAuth0().getAccessTokenSilently()
        }
        const header = {headers:{Authorization: "Bearer "+ state.token}}
        return axiosInstance.delete(url+endpoint, header)
    }

    return {apiGet, apiPost, apiPut, apiDelete, setToken, getToken, getURL, axiosInstance, apiUploadFile, apiDownloadFile}
}